.contact {
    background: #fff;
    width: 100%;
    height: 100%;
    display: none;
    &__wrapper {
        display: flex;
        flex-direction: row;
        height: 100%;
    }
    &__description {
        width: 400px;
        margin: auto;
    }
    &__close {
        color: #ffffff;
        font-weight: 500;
        margin-bottom: 15px;
        font-size: 20px;
        &:hover {
            color: #fff;
        }
    }
    &__title {
        color: #ffffff;
        font-family: Kanit;
        font-size: 55px;
        font-weight: 700;
        line-height: 60px;
        margin-bottom: 10px;
    }
    aside {
        display: flex;
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 40%;
        background-blend-mode: multiply;
        background: rgba(29, 61, 102, 1);
        background-repeat: no-repeat;
        background-size: cover
    }
    article {
        display: flex;
        align-items: center;
        flex: 1;
    }
    &__form {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        form {
            padding: 50px;
        }
    }
     ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #b1b1b1;
    }
     :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #b1b1b1;
    }
     ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #b1b1b1;
    }
}

.form {
    &__inputs-inline {
        display: flex;
        justify-content: space-between;
        >div {
            width: 47%;
        }
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        outline: 0;
        box-shadow: none;
        border: 0 !important;
        background: #fff;
        background-image: none;
        width: 100%;
        padding-left: 16px;
        color: #183b66;
        height: 100%;
        option {
            color: #183b66;
        }
    }
    &__select {
        position: relative;
        display: flex;
        width: 100%;
        height: 3em;
        line-height: 3;
        background: #fff;
        overflow: hidden;
        border-radius: 6px;
        border: 1px solid #183b66;
        min-height: 55px;
        margin-bottom: 15px;
        span {
            width: 100%;
        }
    }
    &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 40px;
    }
    &__button {
        width: 47%;
        min-height: 64px;
        background: #fff;
        img {
            vertical-align: middle;
            margin-left: 13px;
        }
        &.btn-blue {
            background: #183b66;
        }
    }
}

// Modal
.quotation-modal {
    height: auto;
    .contact__wrapper {
        height: auto;
    }
    .message {
        border: 2px solid #0066e3;
        color: #183b66;
        margin: 2em 0.5em 1em;
        padding: 0.2em 1em;
    }
}

.quotation {
    // background: #fff;
    // width: 725px;
    // margin: 0 auto;
    // padding: 20px;
    // position: relative;
    // margin-top: 50px;
    .form {
        padding: 0;
    }
    &__title {
        font-size: 50px;
        font-family: 'kanit';
        font-weight: 700;
        color: #183b66;
        line-height: 60px;
        margin-bottom: 20px;
        text-align: center;
    }
    // .contact__close {
    //     font-size: 35px;
    //     color: #ffffff;
    //     right: 22px;
    //     top: -45px;
    //     position: absolute;
    //     z-index: 9;
    // }
    .btn-blue {
        margin-top: 30px;
        background: #183b66;
        color: #fff;
        margin-bottom: 30px;
        width: 45%;
        &:hover {
            color: #183b66;
        }
    }
    .vs__dropdown-toggle {
        border: 1px solid #183b66;
        margin-bottom: 15px;
        min-height: 53px
    }
    .vs__actions {
        padding: 4px 30px 0 3px;
    }
    .vs--single .vs__selected {
        color: #183b66;
    }
}

input.vs__search::placeholder {
    color: #183b66 !important
}

.quote-message {
    p {
        margin-bottom: 20px;
    }
}

.quotation {
    width: 720px
}

@media (max-width: 1140px) {
    .contact {
        &__description {
            padding-left: 15px;
            padding-right: 15px;
        }
        &__title {
            font-size: 40px;
            line-height: 40px;
        }
    }
}

@media (max-width: 980px) {
    .contact {
        &__wrapper {
            flex-direction: column;
        }
        &__description {
            width: unset;
        }
        article {
            display: block;
            padding-bottom: 40px;
        }
        aside {
            background-size: cover;
            width: 100%;
            margin-bottom: 20px;
            padding: 15px 0;
        }
        &__form {
            display: block
        }
    }
    .fancybox-slide--html .fancybox-close-small {
        color: currentColor;
    }
    .quotation-modal {
        padding: 0 50px;
        aside {
            display: none;
        }
    }
}

@media (max-width: 768px) {
    .contact {
        article {
            display: block;
        }
        form {
            padding-bottom: 50px;
        }
    }
    .form {
        &__inputs-inline {
            flex-direction: column;
        }
        &__inputs-inline {
            >div {
                width: unset;
            }
        }
        &__buttons {
            flex-direction: column;
        }
        &__button {
            width: 100%;
            margin-bottom: 15px;
        }
    }
    .quotation-modal article {
        padding: 0
    }
    .quotation {
        width: 100%
    }
}

@media (max-width: 360px) {
    .contact {
        form {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}