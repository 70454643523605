.newsletter {
  display: flex;
  background: rgba(29,61,102,1);
  background: -moz-linear-gradient(left, rgba(29,61,102,1) 0%, rgba(29,61,102,1) 30%, rgba(224,235,250,1) 30%, rgba(224,235,250,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(29,61,102,1)), color-stop(30%, rgba(29,61,102,1)), color-stop(30%, rgba(224,235,250,1)), color-stop(100%, rgba(224,235,250,1)));
  background: -webkit-linear-gradient(left, rgba(29,61,102,1) 0%, rgba(29,61,102,1) 30%, rgba(224,235,250,1) 30%, rgba(224,235,250,1) 100%);
  background: -o-linear-gradient(left, rgba(29,61,102,1) 0%, rgba(29,61,102,1) 30%, rgba(224,235,250,1) 30%, rgba(224,235,250,1) 100%);
  background: -ms-linear-gradient(left, rgba(29,61,102,1) 0%, rgba(29,61,102,1) 30%, rgba(224,235,250,1) 30%, rgba(224,235,250,1) 100%);
  background: linear-gradient(to right, rgba(29,61,102,1) 0%, rgba(29,61,102,1) 30%, rgba(224,235,250,1) 30%, rgba(224,235,250,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d3d66', endColorstr='#e0ebfa', GradientType=1 );


  &__left-content {
    background: url('./images/newsletter-example.jpg');
    background-size: cover;
    padding-top: 199px;
    padding-bottom: 180px;
    padding-right: 94px;
  }

  &__right-content {
    padding-left: 56px;
  }

  &__title {
    color: #ffffff;
    font-family: Kanit;
    font-size: 60px;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 10px;
  }

  &__description {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
  }

  .form {
    .btn-blue {
      height: 59px;
    }

    .btn-blue {
      background: #183b66;
    }

    .btn-ghost-blue {
      height: 59px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #e0ebfa;

      &:hover {
        cursor: pointer;
        color: #183b66;
        background-color: #ffffff;
    
        &:before {
          transition: all .5s;
          transform: translateX(100%);
        }
      }

      img {
        margin-right: 15px;
      }
    }

  }
}

@media (max-width: 768px) {
  .newsletter {
    background: #e0ebfa;

    // > .container {
    //   padding: 0;
    // }

    &__left-content {
      background: transparent;
      padding: 0;
      padding-left: 50px;
      padding-right: 50px;
    }

    &__right-content {
      padding-left: 50px;
      padding-right: 50px;
    }

    &__title {
      font-size: 35px;
      margin-top: 25px;
      line-height: 45px
    }

    &__title, 
    &__description {
      color: #183b66;
    }
  }
}