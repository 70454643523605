.btn-blue {
  position: relative;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  width: 100%;
  border: 1px solid #183b66;
  padding: 16px;
  text-align: center;
  transition: all .5s;
  overflow: hidden;
  display: block;
  z-index: 1;
  transition: all .5s;
  letter-spacing: 1px;

  &[type='submit']{
    &:hover {
      background: #ffffff;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: #183b66;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all .5s;
  }

  &:hover {
    cursor: pointer;
    color: #183b66;

    &:before {
      transform: translateX(100%);
    }
  }
}

.btn-ghost-blue {
  background: transparent;
  border: 1px solid #183b66;
  border-radius: 6px;
  padding: 16px;
  width: 100%;
  color: #183b66;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 1px;

  &:hover {
    cursor: pointer;
    color: #183b66;

    &:before {
      transition: all .5s;
      transform: translateX(100%);
    }
  }

}

.btn-ghost-gray {
  position: relative;
  display: block;
  border: 1px solid #dadada;
  border-radius: 6px;
  padding: 16px;
  width: 100%;
  color: #0a56b2;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  text-align: center;
  transition: all .5s;
  overflow: hidden;
  display: block;
  z-index: 1;
  letter-spacing: 1px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: #f6f6f6;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all .5s;
  }

  &:hover {
    &:before {
      transform: translateX(100%);
    }
  }
}