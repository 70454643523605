.radiology-team {
    text-align: center;
    &__title {
        margin-bottom: 25px;
        color: #183b66;
        font-family: Kanit;
        font-size: 45px;
        line-height: 1.1;
        font-weight: 700;
    }
    &__description {
        font-size: 30px;
        color: #183b66;
        font-weight: 700;
        line-height: normal;
    }
}