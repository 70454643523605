.choose-service {
  padding-top: 121px;
  padding-bottom: 97px;
  background: url('./images/image-cita.jpg');
  background-size: cover;

  &__title {
    color: #ffffff;
    font-family: Kanit;
    font-size: 55px;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 28px;
  }

  &__description {
    color: #ffffff;
    font-weight: 500;
    line-height: 25.2px;
    margin-bottom: 40px;
  }

  &__btn {
    display: block;
    width: 300px;
    position: relative;
    color: #fff;
    border: 1px solid #ffffff;
    border-radius: 6px;
    padding: 14px 20px;
    text-align: center;
    z-index: 1;
    transition: all .5s;
    overflow: hidden;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: #183b66;
      width: 100%;
      height: 100%;
      z-index: -1;
      transition: all .5s;
    }

    &:hover {
      cursor: pointer;
      background: #fff;
      color: #183b66;

      &:before {
        transform: translateX(100%);
      }
    }
  }
}

@media (max-width: 768px) {
  .choose-service {
    padding: 80px 50px;

    &__title {
      font-size: 35px;
      line-height: 40px;
    }
  }
}

@media (max-width: 480px) {
  .choose-service {
    padding: 80px 10px;

    &__btn {
      width: 100%;
    }
  }
}