.patient {
    background: url('./images/bg-lines.png') no-repeat center center;
    background-size: contain;
    padding-top: 80px;
    padding-bottom: 90px;
    &__title {
        color: #183b66;
        font-family: 'kanit';
        font-size: 55px;
        font-weight: 700;
        line-height: 60px;
        margin-bottom: 20px;
    }
    &__text {
        max-width: 540px;
        margin-bottom: 50px;
    }
    .card {
        border: 1px solid #b1cced;
        min-height: 100%;
        height: auto;
        &__text {
            color: #183b66;
        }
    }
}

.patient .card__text {
    color: #fff
}

@media (max-width: 768px) {
    .patient {
        &__title {
            font-size: 35px;
            line-height: 1;
        }
    }
}