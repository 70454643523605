.insurers {
    padding: 90px 0;
    background: url('./images/bg-lines.png') repeat-y center center;
    &__title {
        color: #183b66;
        font-family: Kanit;
        font-size: 55px;
        font-weight: 700;
        line-height: 1.1;
        margin-bottom: 30px;
    }
    &__more-information {
        font-weight: 600;
        img {
            vertical-align: middle;
            margin-right: 10px;
        }
    }
    &__text {
        margin-bottom: 30px;
    }
    .accordion-insurer {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        .insurer__title {
            box-shadow: 0 11px 13px rgba(0, 0, 0, 0.08);
            border: 1px solid #8aadd9;
            border-radius: 10px;
            background: transparent;
            color: #88afdf;
            font-family: Kanit;
            font-size: 25px;
            font-weight: 700;
            line-height: 60px;
            text-align: center;
        }
        .ac>.ac-q:after {
            display: none;
        }
    }
}

.insurer {
    width: 47%;
    &.is-active {
        .insurer__title {
            box-shadow: 0 11px 13px rgba(0, 0, 0, 0.08);
            background-color: #183b66;
            color: #ffffff;
            font-family: Kanit;
        }
    }
    &__list {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }
}

@media (max-width: 980px) {
    .insurers {
        .accordion-insurer {
            flex-direction: column;
        }
    }
    .insurer {
        width: 100%;
    }
}

@media(max-width:768px) {
    .insurers__title {
        font-size: 40px;
        text-align: center;
    }
}

@media (max-width: 460px) {
    .insurers {
        .accordion-insurer {
            .insurer__title {
                line-height: 1.2;
            }
        }
    }
}