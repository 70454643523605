.services {
    background: #e0ebfa;
    padding-top: 125px;
    padding-bottom: 125px;
    &__title {
        color: #183b66;
        font-family: Kanit;
        font-size: 55px;
        font-weight: 700;
        line-height: 1.1;
        margin-bottom: 30px;
    }
}

.service {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 42px;
    padding-bottom: 42px;
    padding-left: 25px;
    padding-right: 15px;
    background: #fff;
    border-radius: 10px;
    min-height: 158px;
    transition: background 0.4s ease-in;
    &__icon {
        max-width: 75px;
        margin-right: 25px;
    }
    &__title {
        max-width: 215px;
        color: #191919;
        font-weight: 500;
        font-size: 20px;
    }
    &:hover {
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
        background-color: #183b66;
        .service__title {
            color: #ffffff;
            font-weight: 700;
        }
        svg path {
            stroke: #fff;
        }
    }
}

@media (max-width: 768px) {
    .services {
        padding-top: 50px;
        &__title {
            padding-top: 7%;
            line-height: 1;
            font-size: 40px
        }
    }
    .service {
        &__title {
            max-width: unset;
            font-size: 16px;
        }
    }
    div.wpcf7 {
        background-color: #fff;
    }
    .contact__title {
        padding-bottom: 15%;
        padding-top: 15%
    }
}