.grid-item {
    background-color: #ededed;
    border-radius: 8px;
    display: inline-flex;
    align-items: flex-end;
    margin: 5px;
    position: initial !important;
    .new {
        padding: 10px;
        border-radius: 8px;
        width: 100%;
        padding: 20px 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 150px;
    }
    .title {
        width: 100%;
        background-color: #16161699;
        padding: 12px;
        .news-title {
            font-size: 20px;
            color: #fff;
            font-weight: 700;
        }
    }
}

#grid-news {
    /* clearfix */
    &:after {
        content: '';
        display: block;
        clear: both;
    }
    .grid-item:nth-child(2) {
        width: 22%;
        .new {
            height: 631px;
        }
    }
    .grid-item:nth-child(3) {
        width: 22%;
        .new {
            height: 306px;
        }
    }
    .grid-item:nth-child(4) {
        width: 20%;
        .new {
            height: 306px;
        }
    }
    .grid-item:first-child {
        width: 50%;
        .new {
            height: 306px;
        }
    }
}

.news-container {
    padding: 70px 0;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.items-center {
    align-items: center;
}

.goback {
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    &:hover {
        color: #0066e3;
    }
}

.new-body {
    padding: 60px 0 !important;
}

@media (max-width: 768px) {
    .new-body {
        padding: 0 !important;
    }
}