body {
    font-family: 'montserrat';
    font-weight: 400;
    font-size: 16px;
    color: #191919;
    line-height: 26px;
}

.container {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    &.is-fluid {
        padding: 0;
        margin: 0;
    }
}

.mb-35 {
    margin-bottom: 35px;
}

.full-height {
    height: 100vh;
}

.fancybox-content {
    width: 100%;
    height: 85%;
    padding: 0;
    background: transparent;
}

button,
input,
select,
textarea {
    font-family: 'montserrat';
    font-size: 16px;
}

button:hover {
    cursor: pointer;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a3a3a3;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #a3a3a3;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #a3a3a3;
}

.columns {
    margin-top: 0;
    &:last-child {
        margin-bottom: 0;
    }
}

.anchor-link {
    content: '';
    height: 0px;
}