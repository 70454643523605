.banner {
    display: flex;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    &__bg {
        width: 100%;
        transition: all .5s;
        object-fit: cover;
    }
    &__overlay {
        position: absolute;
        width: 100%;
        bottom: 20%;
        &--service {
            bottom: -0.75rem;
            .banner__container {
                padding: 50px;
                border-radius: 10px 10px 0 0;
                background: rgba(#051a34, 0.45)
            }
            .banner__title {
                margin-bottom: 10px;
            }
        }
    }
    &__title {
        color: #ffffff;
        font-family: Kanit;
        font-size: 65px;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 55px;
    }
    &__text {
        color: #ffffff;
    }
    &-appointment__button {
        position: absolute;
        z-index: 5;
        bottom: 0;
        padding: 20px 90px;
        right: 0;
        color: #ffffff;
        font-size: 20px;
        font-weight: 500;
        border-radius: 10px 0 0;
        background-color: #183b66;
        &:hover {
            color: #ffffff;
            background-color: #183b66;
            opacity: 0.7;
            text-shadow: 1px 1px 7px #cccccc;
            &:before {
                transition: all .5s;
                transform: scaleY(1);
            }
        }
        img {
            margin-right: 20px;
            vertical-align: middle;
        }
    }
    .appointment_button.hidde-botton {
        display: none;
    }
}

.banner-appointment__button.js-quotation.hidde-botton {
    display: none;
}

.banner__overlay--service .banner__container {
    min-height: 560px;
}

// Single posts
.single-post {
    .banner {
        height: 100vh;
    }
}

@media (max-width: 1200px) {
    .banner {
        &__title {
            margin-bottom: 20px;
        }
    }
    .page-template-services_detail {
        .banner__overlay--service {
            .banner__container {
                padding: 20px;
            }
        }
        // .banner-appointment {
        //   &__button {
        //     bottom: -60px;
        //     border-radius: 0;
        //   }
        // }
    }
}

@media (max-width: 980px) {
    .banner {
        &__title {
            margin-bottom: 10px;
            font-size: 30px;
            line-height: 1;
        }
    }
}

@media (max-width: 768px) {
    .banner {
        display: block;

        &__title {
            margin-bottom: 10px;
            font-size: 30px;
            line-height: 1;
            color: #183b66;
            text-align: center;
        }

        &__overlay {
            display: none;
        }
        &-appointment__button {
            width: 100%;
            text-align: center;
            border-radius: 0;
            bottom: -60px;
            padding: 20px 15px;
            font-size: 16px;
        }
    }
    // Single posts
    .single-post {
        .banner {
            height: 100%;
        }
    }
}

.scroll-downs {
    position: absolute;
    right: 0;
    bottom: 30px;
    left: 0;
    margin: auto;
    width: 34px;
    height: 55px;
}

.mousey {
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 2px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
}

.scroller {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15, .41, .69, .94);
    animation-iteration-count: infinite;
}

@keyframes scroll {
    0% {
        opacity: 0;
    }
    10% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(15px);
        opacity: 0;
    }
}