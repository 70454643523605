.search {
  form {
    display: flex;
  }

  &__input {
    width: 80%;
    border: 1px solid #fff;
    font-weight: 500;
    font-size: 16px;
    color: #a3a3a3;
    padding: 22px 56px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &__button {
    position: relative;
    width: 300px;
    background-color: #183b66;
    background-image: url('./images/search.png');
    background-repeat: no-repeat;
    background-position: 20% center;
    border: 1px solid #183b66;
    color: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1px;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
      text-shadow: 1px 1px 7px #cccccc;
    }
  }
}