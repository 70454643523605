.card {
    padding: 30px;
    background-color: #183b66;
    border: 1px solid #d7d7d7;
    border-radius: 8px;
    &__title {
        color: #fff;
        font-family: Kanit;
        font-size: 40px;
        font-weight: 700;
        line-height: 60px;
        margin-bottom: 10px;
    }
    &__icon {
        min-height: 34px;
        svg path {
            stroke: #fff;
        }
    }
    &__text {
        color: #fff;
        font-size: 16px;
    }
}