.form {
    &__label {
        display: block;
        font-weight: 500;
        color: #183b66;
        margin-bottom: 7px;
    }
    &__input {
        width: 100%;
        border: 1px solid #183b66;
        border-radius: 6px;
        font-weight: 400;
        color: #173a65;
        padding: 16px 24px;
        font-size: 16px;
        background: transparent;
        margin-bottom: 15px;
    }
     ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #94b2d8;
    }
     :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #94b2d8;
    }
     ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #94b2d8;
    }
}

div.wpcf7-mail-sent-ok {
    border: 2px solid #0066e3 !important;
    color: #183b66!important;
}

.wpcf7-form-control,
form.form input,
form.form select {
    color: #183b66;
}

.requerido {
    color: #183b66;
    font-size: 18px;
    font-weight: bold
}