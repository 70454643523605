.service-conditions {
  background: #ebf4ff;
  padding-top: 90px;
  padding-bottom: 100px;

  &__title {
    color: #183b66;
    font-family: 'kanit';
    font-size: 55px;
    font-weight: 700;
    line-height: 60px;
    text-align: center;
    max-width: 780px;
    margin: auto;
    margin-bottom: 24px;
  }

  &__text {
    text-align: center;
    color: #183b66;
    font-family: 'kanit';
    font-size: 25px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 43px;
    text-align: center;
  }

  &__list {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    list-style: square inside;

    li {
      color: #183b66;
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 768px) {
  .service-conditions {
    &__title {
      font-size: 35px;
      line-height: 1;
    }

    &__text {
      font-size: 20px;
      line-height: 1;
    }

    &__list {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }
  }
}