.header {
    position: fixed;
    top: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    z-index: 9;
    transition: all .5s;
    svg {
        fill: #fff;
    }
    
    &.active {
        background: #183b66;
        .banner__bg {
            width: 90%;
        }
    }
    &.active-blue {
        background: #fff;
        svg {
            fill: #183b66;
        }
        .banner__bg {
            width: 90%;
        }
        .navigation__main a {
            color: #183b66;
        }
        .current-menu-item a,
        .current-page-ancestor a {
            border-bottom: 4px solid #183b66;
        }
    }
}

.header-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.navigation {
    display: flex;
    align-items: center;
    ul {
        display: flex;
        align-items: center;
    }
    a {
        display: block;
    }
}

.current-menu-item a,
.current-page-ancestor a {
    border-bottom: 4px solid #fff;
}

.sitemap .current-menu-item a,
.sitemap .current-page-ancestor a {
    border-bottom: 0;
}

.navigation__main {
    display: flex;
    a {
        padding: 25px 10px;
        margin: 0 10px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }
    ul {
        display: flex;
        align-items: center;
    }
    ul li:last-child a {
        padding: 15px;
        background: #fff;
        border-radius: 6px;
        color: #183b66;
        &:hover {
            opacity: 0.7;
            text-shadow: 1px 1px 7px #cccccc;
        }
    }
}

.navigation__social {
    margin-left: 20px;
    a {
        padding: 0 15px;
    }
    li:hover {
        cursor: pointer;
    }
}

.navigation__mobile {
    display: none;
    margin-left: 35px;
    .menu-btn {
        font-size: 1.7em;
        border: 0;
        background: transparent;
        color: #fff;
    }
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    background: #fff;
}

.lost {
    background: #183b66;
}


@media (max-width: 1024px) {
    .navigation {
        &__main {
            a {
                padding: 25px 5px;
            }
        }
        &__social {
            margin-left: 10px;
        }
    }
}

@media (max-width: 980px) {
    .header {
        .banner__bg {
            width: 80%;
        }
    }
    .navigation {
        &__main {
            display: none
        }
        &__mobile {
            display: block
        }
    }
    .pushy a {
        color: #183b66
    }
}

@media(max-width:768px) {
    .header.active .banner__bg {
        width: 60% !important
    }
    .header .banner__bg {
        width: 55% !important
    }
}

@media (max-width: 480px) {
    .header-wrapper {
        align-items: flex-start;
    }
    .navigation {
        &__social {
            display: none
        }
    }
}