.payment-methods {
    background-color: #eff6ff;
    &__title {
        color: #183b66;
        font-family: Kanit;
        font-size: 55px;
        font-weight: 700;
        line-height: 1;
    }
}

.payment-method {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 80px 0;
    figure {
        max-width: 200px;
    }
    &__image {
        margin-bottom: 30px;
    }
    &__caption {
        color: #183b66;
        font-size: 25px;
    }
}

@media (max-width: 800px) {
    .payment-method {
        flex-direction: column;
        figure {
            margin-bottom: 25px;
            max-width: unset;
        }
        &__image {
            margin-bottom: 5px;
        }
    }
    .payment-methods__title {
        margin-bottom: 45px;
        line-height: 1;
    }
}

@media(max-width:768px) {
    .payment-methods__title {
        font-size: 40px
    }
    .payments-div {
        display: block !important
    }
}