@font-face {
  font-family: 'kanit';
  src: url('./fonts/kanit/Kanit-Regular.eot');
  src: local('Kanit-Regular'), local('Kanit-Regular'),
    url('./fonts/kanit/Kanit-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/kanit/Kanit-Regular.woff') format('woff'),
    url('./fonts/kanit/Kanit-Regular.ttf') format('truetype'),
    url('./fonts/kanit/Kanit-Regular.svg#Kanit-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'kanit';
  src: url('./fonts/kanit/Kanit-SemiBold.eot');
  src: local('Kanit-SemiBold'), local('Kanit-SemiBold'),
    url('./fonts/kanit/Kanit-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/kanit/Kanit-SemiBold.woff') format('woff'),
    url('./fonts/kanit/Kanit-SemiBold.ttf') format('truetype'),
    url('./fonts/kanit/Kanit-SemiBold.svg#Kanit-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'kanit';
  src: url('./fonts/kanit/Kanit-Bold.eot');
  src: local('Kanit-Bold'), local('Kanit-Bold'),
    url('./fonts/kanit/Kanit-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/kanit/Kanit-Bold.woff') format('woff'),
    url('./fonts/kanit/Kanit-Bold.ttf') format('truetype'),
    url('./fonts/kanit/Kanit-Bold.svg#Kanit-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}



@font-face {
  font-family: 'gillsans';
  src: url('./fonts/gillsans/GillSans.eot');
  src: local('GillSans'), local('GillSans'),
    url('./fonts/gillsans/GillSans.eot?#iefix') format('embedded-opentype'),
    url('./fonts/gillsans/GillSans.woff') format('woff'),
    url('./fonts/gillsans/GillSans.ttf') format('truetype'),
    url('./fonts/gillsans/GillSans.svg#GillSans') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat';
  src: url('./fonts/Montserrat/Montserrat-Bold/Montserrat-Bold.eot');
  src: local('Montserrat-Bold'), local('Montserrat-Bold'),
    url('./fonts/Montserrat/Montserrat-Bold/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat/Montserrat-Bold/Montserrat-Bold.woff') format('woff'),
    url('./fonts/Montserrat/Montserrat-Bold/Montserrat-Bold.ttf') format('truetype'),
    url('./fonts/Montserrat/Montserrat-Bold/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat';
  src: url('./fonts/Montserrat/Montserrat-ExtraBold/Montserrat-ExtraBold.eot');
  src: local('Montserrat-ExtraBold'), local('Montserrat-ExtraBold'),
    url('./fonts/Montserrat/Montserrat-ExtraBold/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat/Montserrat-ExtraBold/Montserrat-ExtraBold.woff') format('woff'),
    url('./fonts/Montserrat/Montserrat-ExtraBold/Montserrat-ExtraBold.ttf') format('truetype'),
    url('./fonts/Montserrat/Montserrat-ExtraBold/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat';
  src: url('./fonts/Montserrat/Montserrat-Medium/Montserrat-Medium.eot');
  src: local('Montserrat-Medium'), local('Montserrat-Medium'),
    url('./fonts/Montserrat/Montserrat-Medium/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat/Montserrat-Medium/Montserrat-Medium.woff') format('woff'),
    url('./fonts/Montserrat/Montserrat-Medium/Montserrat-Medium.ttf') format('truetype'),
    url('./fonts/Montserrat/Montserrat-Medium/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular/Montserrat-Regular.eot');
  src: local('Montserrat-Regular'), local('Montserrat-Regular'),
    url('./fonts/Montserrat/Montserrat-Regular/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat/Montserrat-Regular/Montserrat-Regular.woff') format('woff'),
    url('./fonts/Montserrat/Montserrat-Regular/Montserrat-Regular.ttf') format('truetype'),
    url('./fonts/Montserrat/Montserrat-Regular/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat';
  src: url('./fonts/Montserrat/Montserrat-SemiBold/Montserrat-SemiBold.eot');
  src: local('Montserrat-SemiBold'), local('Montserrat-SemiBold'),
    url('./fonts/Montserrat/Montserrat-SemiBold/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserrat/Montserrat-SemiBold/Montserrat-SemiBold.woff') format('woff'),
    url('./fonts/Montserrat/Montserrat-SemiBold/Montserrat-SemiBold.ttf') format('truetype'),
    url('./fonts/Montserrat/Montserrat-SemiBold/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

h1 {
  font-family: 'gillsans';
}