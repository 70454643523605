.tooltip-menu {
  width: 360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  background: #fff;

  img {
    width: 100%;
    margin-right: 5px;
    vertical-align: middle;
  }

  &__left {
    width: 200px;
  }

  &__right {
    flex: 1;
  }

  &__text {
    font-size: 11px;
    line-height: 15.2px;
    text-align: left;
  }

  &__image {
    width: 100%;
  }

  &__navigation {
    padding-left: 30px;
    
    li {
      font-size: 11px;
      padding: 8px 0;
      text-align: left;

      &:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
      }
    }
  }
}

.tooltip-menu-wa {
  width: 290px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background: #fff;

  img {
    width: 40px;
    margin-right: 5px;
    vertical-align: middle;
  }

  &__left {
    width: 200px;
  }

  &__right {
    flex: 1;
  }

  &__text {
    font-size: 11px;
    line-height: 15.2px;
    text-align: left;
  }

  &__image {
    width: 100%;
  }

  &__navigation {
    padding-left: 0px;
    
    li {
      font-size: 11px;
      padding: 8px 0;
      text-align: left;

      &:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
      }
    }
  }
}