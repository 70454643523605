.ac {
    border: 1px solid transparent;
    background: transparent;
    >.ac-q {
        color: #ffffff;
        font-family: "montserrat";
        font-size: 16px;
        font-weight: 500;
        line-height: 25.6px;
        border-bottom: 2px solid #2993fa;
        margin-bottom: 20px;
        &:after {
            content: url('./images/angle-up.png');
        }
        &:focus {
            outline: none;
        }
    }
    >.ac-a {
        p {
            color: #ffffff;
            font-family: "montserrat";
            font-size: 16px;
            font-weight: 500;
            line-height: 25.6px;
            margin-bottom: 5px;
            padding: 0;
            >img {
                margin-right: 20px;
            }
        }
        a {
            color: #0093ff;
            font-size: 16px;
            font-weight: 600;
            &:hover {
                color: #0093ff;
            }
        }
    }
}

.accordion-service {
    .ac {
        &.is-active {
            >.ac-q {
                background-color: #b5d2f6;
                border-bottom: 2px solid #b5d2f6;
                font-weight: 600;
                &::after {
                    content: url('./images/angle-down-blue.png');
                    transition: all .5s;
                }
                &:before {
                    content: "Ver menos";
                    color: #183b66;
                    font-weight: 400;
                    transition: all .5s;
                }
            }
            .ac-a {
                padding: 10px 30px 40px;
                height: auto !important;
            }
        }
        >.ac-q {
            background: #fff;
            margin-bottom: 0;
            color: #183b66;
            font-family: 'Kanit', sans-serif;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            padding: 20px 30px;
            border-radius: 6px;
            transition: all .5s;
            &:after {
                content: url('./images/angle-up-blue.png');
                width: auto;
                right: 30px;
                transition: all .8s;
            }
            &:before {
                content: "Ver más";
                position: absolute;
                right: 150px;
                color: #85a9d5;
                font-family: Kanit;
                text-transform: uppercase;
                transition: all .5s;
            }
        }
        >.ac-a {
            background-color: #b5d2f6;
            a[class^="btn-"] {
                color: #fff;
                display: block;
                margin-bottom: 10px;
                width: 75%;
                margin-right: 0;
                margin-left: auto;
            }
            a.btn-ghost-blue {
                color: #183b66;
            }
            a.btn-blue:hover {
                color: #183b66;
            }
        }
    }
    &__subtitle {
        font-family: 'kanit';
        color: #3365a1;
        text-transform: uppercase;
        margin-bottom: 5px;
    }
    &__content {
        display: flex;
        flex-direction: row;
        article {
            position: relative;
            width: 33.3333%;
            padding-right: 15px;
            &:first-child:after {
                content: '';
                background: #9cc8ff;
                width: 1px;
                height: 80%;
                position: absolute;
                right: 7%;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
    &__list {
        list-style: inside;
        li {
            color: #181818;
            font-size: 16px;
            line-height: 22px;
            padding: 2px 5px;
        }
    }
}

.btn-blue.js-appointment.hidde-botton {
    display: none !important
}

@media (max-width: 1000px) {
    .accordion {
        &-service__content {
            flex-direction: column;
            article {
                width: 100%;
                margin-bottom: 30px;
                &:first-child:after {
                    width: 0;
                }
            }
        }
        &-service {
            .ac {
                >.ac-a a[class^="btn-"] {
                    width: 100%;
                }
            }
        }
    }
}

.ac>.ac-q::after {
    text-align: center;
    width: 15px;
    right: 10px;
    top: 25% !important;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: rotate(180deg) !important;
    position: absolute;
}

@media (max-width: 768px) {
    .accordion {
        &-service {
            .ac {
                &.is-active{
                    >.ac-q {
                        &:after {
                            right: 14px;
                        }
                        &:before {
                            content: "";
                        }
                    }
                }
                >.ac-q {
                    &:after {
                        right: 14px;
                    }
                    &:before {
                        content: "";
                    }
                }
            }
        }
    }
}
