.about-us__description {
    padding-top: 110px;
    padding-bottom: 65px;
    p {
        margin-bottom: 35px;
        padding-right: 40px;
    }
    .btn-blue {
        padding: 16px 50px;
        width: 40%;
        margin: auto;
    }
}

.our-activity {
    background-color: #f6faff;
    padding-top: 88px;
    padding-bottom: 88px;
}

.team {
    padding: 95px 0 50px;
}

@media (max-width: 1200px) {
    .page-template-about-us {
        .banner__title {
            padding-right: 0;
            margin-bottom: 50px;
        }
    }
}

@media (max-width: 980px) {
    about-us {}
}

@media (max-width: 768px) {
    .team {
        padding: 10px 0 50px;
    }
    .radiology-team__title {
        font-size: 30px
    }
    .radiology-team__description {
        font-size: 20px
    }
    .about-us__description {
        padding-top: 5px;
        padding-bottom: 5px
    }
    .about-us__description p {
        text-align: justify;
        margin-top: 45px;
        padding-right: 0
    }
}