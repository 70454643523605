.home-strengths {
    background: url('./images/bg-lines.png') no-repeat center center;
    background-size: cover;
    padding-top: 144px;
    padding-bottom: 144px;
    &__title {
        color: #183b66;
        font-family: Kanit;
        font-size: 55px;
        font-weight: 700;
        line-height: 1.1;
        margin-bottom: 60px;
    }
}

.home-strength {
    padding: 0 36px;
    text-align: center;
    display: block;
    &__icon {
        margin-bottom: 46px;
    }
    &__text {
        color: #191919;
    }
}

@media (max-width: 768px) {
    .home-strengths {
        padding-top: 80px;
        &__title {
            font-size: 35px;
            line-height: 40px;
        }
    }
}

@media (max-width: 480px) {
    .home-strengths {
        padding-top: 80px;
        &--list {
            .column.is-6-mobile {
                width: 100%;
            }
        }
    }
}