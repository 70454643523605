.footer {
    padding: 0;
    background: transparent;
}

.top-footer {
    background-color: #102a4b;
    padding-top: 29px;
    padding-bottom: 35px;
    color: #fff;
    font-size: 12px;
    position: relative;
    a {
        color: #fff;
    }
    &__title {
        font-weight: 700;
        margin-bottom: 10px;
        font-size: 16px;
        img,
        svg {
            margin-right: 10px;
            vertical-align: middle;
        }
    }
    strong {
        color: #fff;
    }
    .btn-light-blue {
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
        padding: 13px 15px;
        background-color: #2a8aff;
        border-radius: 10px 10px 0 0;
        position: absolute;
        bottom: -47px;
        right: 4%;
    }
}

.sitemap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .children {
        display: none;
    }
    ul {
        column-count: 2;
        font-size: 12px;
        text-transform: uppercase;
    }
    a {
        color: #fff;
    }
    a:hover {
        font-weight: 700;
    }
    &__navigation {
        width: 50%;
    }
}

.location {
    a {
        color: #0066e3;
        font-weight: 600;
        margin-left: 15px;
    }
}

.bottom-footer {
    background-color: #add2ff;
    padding-top: 28px;
    padding-bottom: 22px;
    &__title {
        color: #183b66;
        font-family: Kanit;
        font-size: 25px;
        font-weight: 700;
        line-height: 26px;
        text-align: center;
        margin-bottom: 10px;
    }
    ul {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
            align-items: center;
            width: 20%;
            padding-left: 20px;
            padding-right: 20px;
            text-align: center;
        }
    }
}

.copyright {
    background-color: #a6cefd;
    font-size: 12px;
    color: #183b66;
    text-align: center;
    padding: 10px 0;
}

@media (max-width: 768px) {
    .top-footer {
        text-align: center;
    }
    .bottom-footer {
        &__title {
            margin-bottom: 25px;
        }
        ul {
            justify-content: center;
            li {
                &:first-child {
                    width: 100%;
                }
                width: 50%;
                margin-bottom: 15px;
            }
        }
    }
    .sitemap {
        justify-content: center;
        ul {
            column-count: 1;
        }
    }
}

@media (max-width: 480px) {
    .top-footer {
        .btn-light-blue {
            border-radius: 0;
            width: 100%;
            right: 0;
        }
    }
    .sitemap {
        justify-content: center;
        ul {
            column-count: 1;
        }
    }
}