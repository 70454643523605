.search {
    .header {
        position: relative;
        background: #183b66;
    }
    &-result {
        padding-top: 150px;
        padding-bottom: 150px;
        &__search__var {
            margin-bottom: 50px;
        }
        &__top__title {
            font-size: 20px;
        }
    }
    &__input {
        border: 1px solid #183b66;
    }
}

.handyman {
    &__title {
        color: #183b66;
        font-family: Kanit;
        font-size: 40px;
        font-weight: 700;
        line-height: 60px;
        margin-bottom: 30px;
    }
}

.title-search {
    font-size: 30px !important;
    font-weight: bold !important;
    margin-bottom: 4rem;
    color: #183b66
}