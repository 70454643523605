.service-description {
    background: #ebf4ff;
    padding-bottom: 100px;
    &__title {
        color: #183b66;
        font-family: Kanit;
        font-size: 55px;
        font-weight: 700;
        line-height: 60px;
        margin-bottom: 30px;
        max-width: 780px;
    }
}

.service-message {
    border-radius: 0 10px 10px 0;
    background-color: #102a4b;
    padding: 20px 77px 20px;
    box-shadow: -100px 0px 0px 0px #102a4b;
    margin-top: 68px;
    margin-bottom: 60px;
    &__text {
        color: #ffffff;
    }
    a {
        color: #fff;
        font-weight: bold;
    }
}

@media (max-width: 768px) {
    .service-message {
        border-radius: 0;
        box-shadow: unset;
        padding: 20px;
    }
    .service-description {
        &__title {
            font-size: 40px;
            line-height: 1;
        }
    }
}